@font-face {
    font-family: 'HeliosCondBlackC';
    src: url('HeliosCondBlackC-Italic.eot');
    src: local('HeliosCondBlackC Italic'), local('HeliosCondBlackC-Italic'),
        url('HeliosCondBlackC-Italic.eot?#iefix') format('embedded-opentype'),
        url('HeliosCondBlackC-Italic.woff2') format('woff2'),
        url('HeliosCondBlackC-Italic.woff') format('woff'),
        url('HeliosCondBlackC-Italic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosExtC';
    src: url('HeliosExtC.eot');
    src: local('HeliosExtC'),
        url('HeliosExtC.eot?#iefix') format('embedded-opentype'),
        url('HeliosExtC.woff2') format('woff2'),
        url('HeliosExtC.woff') format('woff'),
        url('HeliosExtC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtThinC';
    src: url('HeliosExtThinC-Italic.eot');
    src: local('HeliosExtThinC Italic'), local('HeliosExtThinC-Italic'),
        url('HeliosExtThinC-Italic.eot?#iefix') format('embedded-opentype'),
        url('HeliosExtThinC-Italic.woff2') format('woff2'),
        url('HeliosExtThinC-Italic.woff') format('woff'),
        url('HeliosExtThinC-Italic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosCondThinC';
    src: url('HeliosCondThinC-Italic.eot');
    src: local('HeliosCondThinC Italic'), local('HeliosCondThinC-Italic'),
        url('HeliosCondThinC-Italic.eot?#iefix') format('embedded-opentype'),
        url('HeliosCondThinC-Italic.woff2') format('woff2'),
        url('HeliosCondThinC-Italic.woff') format('woff'),
        url('HeliosCondThinC-Italic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosCondC';
    src: url('HeliosCondC-BoldItalic.eot');
    src: local('HeliosCondC Bold Italic'), local('HeliosCondC-BoldItalic'),
        url('HeliosCondC-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('HeliosCondC-BoldItalic.woff2') format('woff2'),
        url('HeliosCondC-BoldItalic.woff') format('woff'),
        url('HeliosCondC-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosC';
    src: url('HeliosC-Italic.eot');
    src: local('HeliosC Italic'), local('HeliosC-Italic'),
        url('HeliosC-Italic.eot?#iefix') format('embedded-opentype'),
        url('HeliosC-Italic.woff2') format('woff2'),
        url('HeliosC-Italic.woff') format('woff'),
        url('HeliosC-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosLightC';
    src: url('HeliosLightC.eot');
    src: local('HeliosLightC'),
        url('HeliosLightC.eot?#iefix') format('embedded-opentype'),
        url('HeliosLightC.woff2') format('woff2'),
        url('HeliosLightC.woff') format('woff'),
        url('HeliosLightC.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtLightC';
    src: url('HeliosExtLightC-Italic.eot');
    src: local('HeliosExtLightC Italic'), local('HeliosExtLightC-Italic'),
        url('HeliosExtLightC-Italic.eot?#iefix') format('embedded-opentype'),
        url('HeliosExtLightC-Italic.woff2') format('woff2'),
        url('HeliosExtLightC-Italic.woff') format('woff'),
        url('HeliosExtLightC-Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosCondC';
    src: url('HeliosCondC-Italic.eot');
    src: local('HeliosCondC Italic'), local('HeliosCondC-Italic'),
        url('HeliosCondC-Italic.eot?#iefix') format('embedded-opentype'),
        url('HeliosCondC-Italic.woff2') format('woff2'),
        url('HeliosCondC-Italic.woff') format('woff'),
        url('HeliosCondC-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosC';
    src: url('HeliosC.eot');
    src: local('HeliosC'),
        url('HeliosC.eot?#iefix') format('embedded-opentype'),
        url('HeliosC.woff2') format('woff2'),
        url('HeliosC.woff') format('woff'),
        url('HeliosC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosCondC';
    src: url('HeliosCondC-Bold.eot');
    src: local('HeliosCondC Bold'), local('HeliosCondC-Bold'),
        url('HeliosCondC-Bold.eot?#iefix') format('embedded-opentype'),
        url('HeliosCondC-Bold.woff2') format('woff2'),
        url('HeliosCondC-Bold.woff') format('woff'),
        url('HeliosCondC-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtBlackC';
    src: url('HeliosExtBlackC-Italic.eot');
    src: local('HeliosExtBlackC Italic'), local('HeliosExtBlackC-Italic'),
        url('HeliosExtBlackC-Italic.eot?#iefix') format('embedded-opentype'),
        url('HeliosExtBlackC-Italic.woff2') format('woff2'),
        url('HeliosExtBlackC-Italic.woff') format('woff'),
        url('HeliosExtBlackC-Italic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosLightC';
    src: url('HeliosLightC-Italic.eot');
    src: local('HeliosLightC Italic'), local('HeliosLightC-Italic'),
        url('HeliosLightC-Italic.eot?#iefix') format('embedded-opentype'),
        url('HeliosLightC-Italic.woff2') format('woff2'),
        url('HeliosLightC-Italic.woff') format('woff'),
        url('HeliosLightC-Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosExtC';
    src: url('HeliosExtC-Italic.eot');
    src: local('HeliosExtC Italic'), local('HeliosExtC-Italic'),
        url('HeliosExtC-Italic.eot?#iefix') format('embedded-opentype'),
        url('HeliosExtC-Italic.woff2') format('woff2'),
        url('HeliosExtC-Italic.woff') format('woff'),
        url('HeliosExtC-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosUltraCompressedC';
    src: url('HeliosUltraCompressedC.eot');
    src: local('HeliosUltraCompressedC'),
        url('HeliosUltraCompressedC.eot?#iefix') format('embedded-opentype'),
        url('HeliosUltraCompressedC.woff2') format('woff2'),
        url('HeliosUltraCompressedC.woff') format('woff'),
        url('HeliosUltraCompressedC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtBlackC';
    src: url('HeliosExtBlackC.eot');
    src: local('HeliosExtBlackC'),
        url('HeliosExtBlackC.eot?#iefix') format('embedded-opentype'),
        url('HeliosExtBlackC.woff2') format('woff2'),
        url('HeliosExtBlackC.woff') format('woff'),
        url('HeliosExtBlackC.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosCondThinC';
    src: url('HeliosCondThinC.eot');
    src: local('HeliosCondThinC'),
        url('HeliosCondThinC.eot?#iefix') format('embedded-opentype'),
        url('HeliosCondThinC.woff2') format('woff2'),
        url('HeliosCondThinC.woff') format('woff'),
        url('HeliosCondThinC.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosThinC';
    src: url('HeliosThinC.eot');
    src: local('HeliosThinC'),
        url('HeliosThinC.eot?#iefix') format('embedded-opentype'),
        url('HeliosThinC.woff2') format('woff2'),
        url('HeliosThinC.woff') format('woff'),
        url('HeliosThinC.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtC';
    src: url('HeliosExtC-Bold.eot');
    src: local('HeliosExtC Bold'), local('HeliosExtC-Bold'),
        url('HeliosExtC-Bold.eot?#iefix') format('embedded-opentype'),
        url('HeliosExtC-Bold.woff2') format('woff2'),
        url('HeliosExtC-Bold.woff') format('woff'),
        url('HeliosExtC-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtraCompressedC';
    src: url('HeliosExtraCompressedC.eot');
    src: local('HeliosExtraCompressedC'),
        url('HeliosExtraCompressedC.eot?#iefix') format('embedded-opentype'),
        url('HeliosExtraCompressedC.woff2') format('woff2'),
        url('HeliosExtraCompressedC.woff') format('woff'),
        url('HeliosExtraCompressedC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosBlackC';
    src: url('HeliosBlackC-Italic.eot');
    src: local('HeliosBlackC Italic'), local('HeliosBlackC-Italic'),
        url('HeliosBlackC-Italic.eot?#iefix') format('embedded-opentype'),
        url('HeliosBlackC-Italic.woff2') format('woff2'),
        url('HeliosBlackC-Italic.woff') format('woff'),
        url('HeliosBlackC-Italic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosC';
    src: url('HeliosC-BoldItalic.eot');
    src: local('HeliosC Bold Italic'), local('HeliosC-BoldItalic'),
        url('HeliosC-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('HeliosC-BoldItalic.woff2') format('woff2'),
        url('HeliosC-BoldItalic.woff') format('woff'),
        url('HeliosC-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosCondLightC';
    src: url('HeliosCondLightC.eot');
    src: local('HeliosCondLightC'),
        url('HeliosCondLightC.eot?#iefix') format('embedded-opentype'),
        url('HeliosCondLightC.woff2') format('woff2'),
        url('HeliosCondLightC.woff') format('woff'),
        url('HeliosCondLightC.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosCondC';
    src: url('HeliosCondC.eot');
    src: local('HeliosCondC'),
        url('HeliosCondC.eot?#iefix') format('embedded-opentype'),
        url('HeliosCondC.woff2') format('woff2'),
        url('HeliosCondC.woff') format('woff'),
        url('HeliosCondC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosCondLightC';
    src: url('HeliosCondLightC-Italic.eot');
    src: local('HeliosCondLightC Italic'), local('HeliosCondLightC-Italic'),
        url('HeliosCondLightC-Italic.eot?#iefix') format('embedded-opentype'),
        url('HeliosCondLightC-Italic.woff2') format('woff2'),
        url('HeliosCondLightC-Italic.woff') format('woff'),
        url('HeliosCondLightC-Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosCondBlackC';
    src: url('HeliosCondBlackC.eot');
    src: local('HeliosCondBlackC'),
        url('HeliosCondBlackC.eot?#iefix') format('embedded-opentype'),
        url('HeliosCondBlackC.woff2') format('woff2'),
        url('HeliosCondBlackC.woff') format('woff'),
        url('HeliosCondBlackC.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosThinC';
    src: url('HeliosThinC-Italic.eot');
    src: local('HeliosThinC Italic'), local('HeliosThinC-Italic'),
        url('HeliosThinC-Italic.eot?#iefix') format('embedded-opentype'),
        url('HeliosThinC-Italic.woff2') format('woff2'),
        url('HeliosThinC-Italic.woff') format('woff'),
        url('HeliosThinC-Italic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosC';
    src: url('HeliosC-Bold.eot');
    src: local('HeliosC Bold'), local('HeliosC-Bold'),
        url('HeliosC-Bold.eot?#iefix') format('embedded-opentype'),
        url('HeliosC-Bold.woff2') format('woff2'),
        url('HeliosC-Bold.woff') format('woff'),
        url('HeliosC-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtC';
    src: url('HeliosExtC-BoldItalic.eot');
    src: local('HeliosExtC Bold Italic'), local('HeliosExtC-BoldItalic'),
        url('HeliosExtC-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('HeliosExtC-BoldItalic.woff2') format('woff2'),
        url('HeliosExtC-BoldItalic.woff') format('woff'),
        url('HeliosExtC-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosBlackC';
    src: url('HeliosBlackC.eot');
    src: local('HeliosBlackC'),
        url('HeliosBlackC.eot?#iefix') format('embedded-opentype'),
        url('HeliosBlackC.woff2') format('woff2'),
        url('HeliosBlackC.woff') format('woff'),
        url('HeliosBlackC.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtThinC';
    src: url('HeliosExtThinC.eot');
    src: local('HeliosExtThinC'),
        url('HeliosExtThinC.eot?#iefix') format('embedded-opentype'),
        url('HeliosExtThinC.woff2') format('woff2'),
        url('HeliosExtThinC.woff') format('woff'),
        url('HeliosExtThinC.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtLightC';
    src: url('HeliosExtLightC.eot');
    src: local('HeliosExtLightC'),
        url('HeliosExtLightC.eot?#iefix') format('embedded-opentype'),
        url('HeliosExtLightC.woff2') format('woff2'),
        url('HeliosExtLightC.woff') format('woff'),
        url('HeliosExtLightC.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosCompressedC';
    src: url('HeliosCompressedC.eot');
    src: local('HeliosCompressedC'),
        url('HeliosCompressedC.eot?#iefix') format('embedded-opentype'),
        url('HeliosCompressedC.woff2') format('woff2'),
        url('HeliosCompressedC.woff') format('woff'),
        url('HeliosCompressedC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

