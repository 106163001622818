@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Regular.19055ba0.eot);
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url(/static/media/Gilroy-Regular.19055ba0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Regular.f6c9931f.woff2) format('woff2'),
        url(/static/media/Gilroy-Regular.ebe86c03.woff) format('woff'),
        url(/static/media/Gilroy-Regular.fa652e76.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-ExtraboldItalic.d4bac2be.eot);
    src: local('Gilroy Extrabold Italic'), local('Gilroy-ExtraboldItalic'),
        url(/static/media/Gilroy-ExtraboldItalic.d4bac2be.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-ExtraboldItalic.cb765d86.woff2) format('woff2'),
        url(/static/media/Gilroy-ExtraboldItalic.a15d926a.woff) format('woff'),
        url(/static/media/Gilroy-ExtraboldItalic.465aa5d0.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Bold.aa68c169.eot);
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url(/static/media/Gilroy-Bold.aa68c169.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Bold.ef7c40ff.woff2) format('woff2'),
        url(/static/media/Gilroy-Bold.0607a1bc.woff) format('woff'),
        url(/static/media/Gilroy-Bold.901a60fc.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Black.15c6a98f.eot);
    src: local('Gilroy Black'), local('Gilroy-Black'),
        url(/static/media/Gilroy-Black.15c6a98f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Black.ae06da6f.woff2) format('woff2'),
        url(/static/media/Gilroy-Black.a9df216d.woff) format('woff'),
        url(/static/media/Gilroy-Black.4628979a.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Light.f498309f.eot);
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url(/static/media/Gilroy-Light.f498309f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Light.c2e7a6dc.woff2) format('woff2'),
        url(/static/media/Gilroy-Light.368f09f5.woff) format('woff'),
        url(/static/media/Gilroy-Light.0ac7c2f9.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Semibold.54bc2f81.eot);
    src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
        url(/static/media/Gilroy-Semibold.54bc2f81.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Semibold.7726b19d.woff2) format('woff2'),
        url(/static/media/Gilroy-Semibold.5b042906.woff) format('woff'),
        url(/static/media/Gilroy-Semibold.41fa36c3.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Medium.2fea647f.eot);
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url(/static/media/Gilroy-Medium.2fea647f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Medium.acd4e613.woff2) format('woff2'),
        url(/static/media/Gilroy-Medium.5329bc50.woff) format('woff'),
        url(/static/media/Gilroy-Medium.1c23f810.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-MediumItalic.2d3fcc2b.eot);
    src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
        url(/static/media/Gilroy-MediumItalic.2d3fcc2b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-MediumItalic.56b6f6d9.woff2) format('woff2'),
        url(/static/media/Gilroy-MediumItalic.e50c6ab1.woff) format('woff'),
        url(/static/media/Gilroy-MediumItalic.89dade8d.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-BlackItalic.0c22099e.eot);
    src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
        url(/static/media/Gilroy-BlackItalic.0c22099e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-BlackItalic.a115605b.woff2) format('woff2'),
        url(/static/media/Gilroy-BlackItalic.c04a0aea.woff) format('woff'),
        url(/static/media/Gilroy-BlackItalic.9d038b5d.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-UltraLight.69d95728.eot);
    src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
        url(/static/media/Gilroy-UltraLight.69d95728.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-UltraLight.378f4351.woff2) format('woff2'),
        url(/static/media/Gilroy-UltraLight.9169fa71.woff) format('woff'),
        url(/static/media/Gilroy-UltraLight.6c356bf4.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-RegularItalic.3b7071f6.eot);
    src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
        url(/static/media/Gilroy-RegularItalic.3b7071f6.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-RegularItalic.5c23dc85.woff2) format('woff2'),
        url(/static/media/Gilroy-RegularItalic.1a8de217.woff) format('woff'),
        url(/static/media/Gilroy-RegularItalic.5f3ed3d8.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-SemiboldItalic.df9d884f.eot);
    src: local('Gilroy Semibold Italic'), local('Gilroy-SemiboldItalic'),
        url(/static/media/Gilroy-SemiboldItalic.df9d884f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-SemiboldItalic.f573f3d8.woff2) format('woff2'),
        url(/static/media/Gilroy-SemiboldItalic.b7e83a7d.woff) format('woff'),
        url(/static/media/Gilroy-SemiboldItalic.4d55ee19.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-HeavyItalic.df7d31c8.eot);
    src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
        url(/static/media/Gilroy-HeavyItalic.df7d31c8.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-HeavyItalic.1260ebe3.woff2) format('woff2'),
        url(/static/media/Gilroy-HeavyItalic.25ba04db.woff) format('woff'),
        url(/static/media/Gilroy-HeavyItalic.4e271337.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Extrabold.35fe3e3e.eot);
    src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
        url(/static/media/Gilroy-Extrabold.35fe3e3e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Extrabold.5c5942d3.woff2) format('woff2'),
        url(/static/media/Gilroy-Extrabold.f39ff81c.woff) format('woff'),
        url(/static/media/Gilroy-Extrabold.92e435f7.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-BoldItalic.59f5bb14.eot);
    src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
        url(/static/media/Gilroy-BoldItalic.59f5bb14.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-BoldItalic.990e3c5e.woff2) format('woff2'),
        url(/static/media/Gilroy-BoldItalic.76c9191d.woff) format('woff'),
        url(/static/media/Gilroy-BoldItalic.244d410b.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-UltraLightItalic.a6fc8679.eot);
    src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
        url(/static/media/Gilroy-UltraLightItalic.a6fc8679.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-UltraLightItalic.9e044996.woff2) format('woff2'),
        url(/static/media/Gilroy-UltraLightItalic.3c8027c9.woff) format('woff'),
        url(/static/media/Gilroy-UltraLightItalic.68a9a27f.ttf) format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-LightItalic.8c6863c1.eot);
    src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
        url(/static/media/Gilroy-LightItalic.8c6863c1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-LightItalic.8dde5723.woff2) format('woff2'),
        url(/static/media/Gilroy-LightItalic.d899841d.woff) format('woff'),
        url(/static/media/Gilroy-LightItalic.7b680060.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Heavy.d3e25fef.eot);
    src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
        url(/static/media/Gilroy-Heavy.d3e25fef.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Heavy.19b3f6fe.woff2) format('woff2'),
        url(/static/media/Gilroy-Heavy.406f336a.woff) format('woff'),
        url(/static/media/Gilroy-Heavy.bf729a99.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Thin.a479c0c1.eot);
    src: local('Gilroy Thin'), local('Gilroy-Thin'),
        url(/static/media/Gilroy-Thin.a479c0c1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Thin.8d8f3d8a.woff2) format('woff2'),
        url(/static/media/Gilroy-Thin.4b118de0.woff) format('woff'),
        url(/static/media/Gilroy-Thin.fc813b75.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-ThinItalic.cea6b0a2.eot);
    src: local('Gilroy Thin Italic'), local('Gilroy-ThinItalic'),
        url(/static/media/Gilroy-ThinItalic.cea6b0a2.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-ThinItalic.aa21bf23.woff2) format('woff2'),
        url(/static/media/Gilroy-ThinItalic.214652f4.woff) format('woff'),
        url(/static/media/Gilroy-ThinItalic.aa74839c.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}


@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ExtraLight.da136e94.eot);
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
        url(/static/media/Montserrat-ExtraLight.da136e94.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-ExtraLight.a472679e.woff2) format('woff2'),
        url(/static/media/Montserrat-ExtraLight.b6e37b33.woff) format('woff'),
        url(/static/media/Montserrat-ExtraLight.ddc3ab52.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-MediumItalic.add94c67.eot);
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
        url(/static/media/Montserrat-MediumItalic.add94c67.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-MediumItalic.b458b21c.woff2) format('woff2'),
        url(/static/media/Montserrat-MediumItalic.b3fe2d7f.woff) format('woff'),
        url(/static/media/Montserrat-MediumItalic.509706ef.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Italic.66049cd4.eot);
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
        url(/static/media/Montserrat-Italic.66049cd4.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-Italic.f2932d92.woff2) format('woff2'),
        url(/static/media/Montserrat-Italic.9d8693d3.woff) format('woff'),
        url(/static/media/Montserrat-Italic.ec9cfd75.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Regular.3220cdd4.eot);
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url(/static/media/Montserrat-Regular.3220cdd4.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-Regular.8b56391e.woff2) format('woff2'),
        url(/static/media/Montserrat-Regular.3db65dc4.woff) format('woff'),
        url(/static/media/Montserrat-Regular.317b418e.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ThinItalic.372c7bef.eot);
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
        url(/static/media/Montserrat-ThinItalic.372c7bef.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-ThinItalic.0b8a2fb9.woff2) format('woff2'),
        url(/static/media/Montserrat-ThinItalic.f7ab4781.woff) format('woff'),
        url(/static/media/Montserrat-ThinItalic.f4e2c987.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Bold.4b33f3d3.eot);
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url(/static/media/Montserrat-Bold.4b33f3d3.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-Bold.cfceaf4e.woff2) format('woff2'),
        url(/static/media/Montserrat-Bold.180ba33d.woff) format('woff'),
        url(/static/media/Montserrat-Bold.5885feb2.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Light.d66eb8dd.eot);
    src: local('Montserrat Light'), local('Montserrat-Light'),
        url(/static/media/Montserrat-Light.d66eb8dd.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-Light.cf7b6a95.woff2) format('woff2'),
        url(/static/media/Montserrat-Light.be142e6c.woff) format('woff'),
        url(/static/media/Montserrat-Light.c3468d41.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Black.fe4a1ef2.eot);
    src: local('Montserrat Black'), local('Montserrat-Black'),
        url(/static/media/Montserrat-Black.fe4a1ef2.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-Black.bed14d97.woff2) format('woff2'),
        url(/static/media/Montserrat-Black.a99d8db9.woff) format('woff'),
        url(/static/media/Montserrat-Black.e410a532.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Medium.418e43db.eot);
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
        url(/static/media/Montserrat-Medium.418e43db.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-Medium.d6bbb7c3.woff2) format('woff2'),
        url(/static/media/Montserrat-Medium.d42dad28.woff) format('woff'),
        url(/static/media/Montserrat-Medium.693c527a.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ExtraLightItalic.cfa355ee.eot);
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
        url(/static/media/Montserrat-ExtraLightItalic.cfa355ee.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-ExtraLightItalic.d2cab369.woff2) format('woff2'),
        url(/static/media/Montserrat-ExtraLightItalic.0ddbc429.woff) format('woff'),
        url(/static/media/Montserrat-ExtraLightItalic.5272ddc9.ttf) format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-BlackItalic.e885010b.eot);
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
        url(/static/media/Montserrat-BlackItalic.e885010b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-BlackItalic.e9689db0.woff2) format('woff2'),
        url(/static/media/Montserrat-BlackItalic.3ff31f43.woff) format('woff'),
        url(/static/media/Montserrat-BlackItalic.723da89d.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ExtraBoldItalic.b3b3dee5.eot);
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
        url(/static/media/Montserrat-ExtraBoldItalic.b3b3dee5.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-ExtraBoldItalic.2db49a6d.woff2) format('woff2'),
        url(/static/media/Montserrat-ExtraBoldItalic.49ebad33.woff) format('woff'),
        url(/static/media/Montserrat-ExtraBoldItalic.d19b715d.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-SemiBoldItalic.81bf66e6.eot);
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
        url(/static/media/Montserrat-SemiBoldItalic.81bf66e6.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-SemiBoldItalic.de2c4a2c.woff2) format('woff2'),
        url(/static/media/Montserrat-SemiBoldItalic.92219255.woff) format('woff'),
        url(/static/media/Montserrat-SemiBoldItalic.9ee86609.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ExtraBold.3e1f7a55.eot);
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
        url(/static/media/Montserrat-ExtraBold.3e1f7a55.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-ExtraBold.724e2fc5.woff2) format('woff2'),
        url(/static/media/Montserrat-ExtraBold.75ee4192.woff) format('woff'),
        url(/static/media/Montserrat-ExtraBold.0697040c.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-BoldItalic.075cad89.eot);
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
        url(/static/media/Montserrat-BoldItalic.075cad89.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-BoldItalic.90543f6d.woff2) format('woff2'),
        url(/static/media/Montserrat-BoldItalic.e69710e7.woff) format('woff'),
        url(/static/media/Montserrat-BoldItalic.74b6e083.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-LightItalic.0e5a40c0.eot);
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
        url(/static/media/Montserrat-LightItalic.0e5a40c0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-LightItalic.dd2bb2d6.woff2) format('woff2'),
        url(/static/media/Montserrat-LightItalic.7c9151ed.woff) format('woff'),
        url(/static/media/Montserrat-LightItalic.bd32320e.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-SemiBold.262fb106.eot);
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url(/static/media/Montserrat-SemiBold.262fb106.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-SemiBold.1d679c82.woff2) format('woff2'),
        url(/static/media/Montserrat-SemiBold.19721359.woff) format('woff'),
        url(/static/media/Montserrat-SemiBold.87718949.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Thin.8067c90c.eot);
    src: local('Montserrat Thin'), local('Montserrat-Thin'),
        url(/static/media/Montserrat-Thin.8067c90c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Montserrat-Thin.5fa2688a.woff2) format('woff2'),
        url(/static/media/Montserrat-Thin.446ea183.woff) format('woff'),
        url(/static/media/Montserrat-Thin.555f03e2.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}


@font-face {
    font-family: 'Proxima Nova Bl';
    src: url(/static/media/ProximaNova-Black.2732ca46.eot);
    src: local('Proxima Nova Black'), local('ProximaNova-Black'),
        url(/static/media/ProximaNova-Black.2732ca46.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNova-Black.5eca80c5.woff2) format('woff2'),
        url(/static/media/ProximaNova-Black.6cad02f9.woff) format('woff'),
        url(/static/media/ProximaNova-Black.8bd42e12.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url(/static/media/ProximaNova-Extrabld.3f4f4600.eot);
    src: local('Proxima Nova Extrabold'), local('ProximaNova-Extrabld'),
        url(/static/media/ProximaNova-Extrabld.3f4f4600.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNova-Extrabld.51e73062.woff2) format('woff2'),
        url(/static/media/ProximaNova-Extrabld.c611b001.woff) format('woff'),
        url(/static/media/ProximaNova-Extrabld.b27eab22.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url(/static/media/ProximaNova-SemiboldIt.803f8d87.eot);
    src: local('Proxima Nova Semibold Italic'), local('ProximaNova-SemiboldIt'),
        url(/static/media/ProximaNova-SemiboldIt.803f8d87.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNova-SemiboldIt.831919c1.woff2) format('woff2'),
        url(/static/media/ProximaNova-SemiboldIt.60d9f063.woff) format('woff'),
        url(/static/media/ProximaNova-SemiboldIt.c9778e1a.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url(/static/media/ProximaNovaT-Thin.34e2ad51.eot);
    src: local('Proxima Nova Thin'), local('ProximaNovaT-Thin'),
        url(/static/media/ProximaNovaT-Thin.34e2ad51.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNovaT-Thin.bd087ed2.woff2) format('woff2'),
        url(/static/media/ProximaNovaT-Thin.10beeac5.woff) format('woff'),
        url(/static/media/ProximaNovaT-Thin.47dd23ed.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url(/static/media/ProximaNova-Light.21ab0a9e.eot);
    src: local('Proxima Nova Light'), local('ProximaNova-Light'),
        url(/static/media/ProximaNova-Light.21ab0a9e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNova-Light.862957f4.woff2) format('woff2'),
        url(/static/media/ProximaNova-Light.7990e518.woff) format('woff'),
        url(/static/media/ProximaNova-Light.4e6430d1.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Bl';
    src: url(/static/media/ProximaNova-BlackIt.b925af54.eot);
    src: local('Proxima Nova Black Italic'), local('ProximaNova-BlackIt'),
        url(/static/media/ProximaNova-BlackIt.b925af54.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNova-BlackIt.08cbfafd.woff2) format('woff2'),
        url(/static/media/ProximaNova-BlackIt.d0dd9333.woff) format('woff'),
        url(/static/media/ProximaNova-BlackIt.411bd9ef.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url(/static/media/ProximaNova-BoldIt.92a839be.eot);
    src: local('Proxima Nova Bold Italic'), local('ProximaNova-BoldIt'),
        url(/static/media/ProximaNova-BoldIt.92a839be.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNova-BoldIt.39f4cb8e.woff2) format('woff2'),
        url(/static/media/ProximaNova-BoldIt.df2baac3.woff) format('woff'),
        url(/static/media/ProximaNova-BoldIt.2c76cdfd.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url(/static/media/ProximaNova-ThinIt.2fc09bf9.eot);
    src: local('Proxima Nova Thin Italic'), local('ProximaNova-ThinIt'),
        url(/static/media/ProximaNova-ThinIt.2fc09bf9.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNova-ThinIt.97a93c2a.woff2) format('woff2'),
        url(/static/media/ProximaNova-ThinIt.3966dfa7.woff) format('woff'),
        url(/static/media/ProximaNova-ThinIt.ae45624e.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url(/static/media/ProximaNova-Bold.c5ff8044.eot);
    src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
        url(/static/media/ProximaNova-Bold.c5ff8044.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNova-Bold.c84883df.woff2) format('woff2'),
        url(/static/media/ProximaNova-Bold.7bbe1c88.woff) format('woff'),
        url(/static/media/ProximaNova-Bold.f3c7fec7.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url(/static/media/ProximaNova-RegularIt.9f23a4db.eot);
    src: local('Proxima Nova Regular Italic'), local('ProximaNova-RegularIt'),
        url(/static/media/ProximaNova-RegularIt.9f23a4db.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNova-RegularIt.1836fee1.woff2) format('woff2'),
        url(/static/media/ProximaNova-RegularIt.c3a30ca6.woff) format('woff'),
        url(/static/media/ProximaNova-RegularIt.20670ec5.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url(/static/media/ProximaNova-ExtrabldIt.25b0de0b.eot);
    src: local('Proxima Nova Extrabold Italic'), local('ProximaNova-ExtrabldIt'),
        url(/static/media/ProximaNova-ExtrabldIt.25b0de0b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNova-ExtrabldIt.af60ff71.woff2) format('woff2'),
        url(/static/media/ProximaNova-ExtrabldIt.786e72f7.woff) format('woff'),
        url(/static/media/ProximaNova-ExtrabldIt.5d3eabe6.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url(/static/media/ProximaNova-Regular.c23787c5.eot);
    src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
        url(/static/media/ProximaNova-Regular.c23787c5.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNova-Regular.c4c3616c.woff2) format('woff2'),
        url(/static/media/ProximaNova-Regular.3d59ce3e.woff) format('woff'),
        url(/static/media/ProximaNova-Regular.db098dc7.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url(/static/media/ProximaNova-LightIt.225bb745.eot);
    src: local('Proxima Nova Light Italic'), local('ProximaNova-LightIt'),
        url(/static/media/ProximaNova-LightIt.225bb745.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNova-LightIt.d66c18cb.woff2) format('woff2'),
        url(/static/media/ProximaNova-LightIt.4b600aa9.woff) format('woff'),
        url(/static/media/ProximaNova-LightIt.5a76d7bf.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url(/static/media/ProximaNova-Semibold.4d27e522.eot);
    src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
        url(/static/media/ProximaNova-Semibold.4d27e522.eot?#iefix) format('embedded-opentype'),
        url(/static/media/ProximaNova-Semibold.54b9eb90.woff2) format('woff2'),
        url(/static/media/ProximaNova-Semibold.9d060ce6.woff) format('woff'),
        url(/static/media/ProximaNova-Semibold.dfa26f09.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'HeliosCondBlackC';
    src: url(/static/media/HeliosCondBlackC-Italic.a66f7e34.eot);
    src: local('HeliosCondBlackC Italic'), local('HeliosCondBlackC-Italic'),
        url(/static/media/HeliosCondBlackC-Italic.a66f7e34.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosCondBlackC-Italic.47e18204.woff2) format('woff2'),
        url(/static/media/HeliosCondBlackC-Italic.951d32e9.woff) format('woff'),
        url(/static/media/HeliosCondBlackC-Italic.303a8e2d.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosExtC';
    src: url(/static/media/HeliosExtC.c08e51d7.eot);
    src: local('HeliosExtC'),
        url(/static/media/HeliosExtC.c08e51d7.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosExtC.d0e85f12.woff2) format('woff2'),
        url(/static/media/HeliosExtC.5a4e35b6.woff) format('woff'),
        url(/static/media/HeliosExtC.46ba0cda.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtThinC';
    src: url(/static/media/HeliosExtThinC-Italic.47bb3bd1.eot);
    src: local('HeliosExtThinC Italic'), local('HeliosExtThinC-Italic'),
        url(/static/media/HeliosExtThinC-Italic.47bb3bd1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosExtThinC-Italic.d0af7d09.woff2) format('woff2'),
        url(/static/media/HeliosExtThinC-Italic.474e12b2.woff) format('woff'),
        url(/static/media/HeliosExtThinC-Italic.7254fa23.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosCondThinC';
    src: url(/static/media/HeliosCondThinC-Italic.0a6ce14b.eot);
    src: local('HeliosCondThinC Italic'), local('HeliosCondThinC-Italic'),
        url(/static/media/HeliosCondThinC-Italic.0a6ce14b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosCondThinC-Italic.d155cbae.woff2) format('woff2'),
        url(/static/media/HeliosCondThinC-Italic.74c49931.woff) format('woff'),
        url(/static/media/HeliosCondThinC-Italic.bd8b53c5.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosCondC';
    src: url(/static/media/HeliosCondC-BoldItalic.1e63cc9b.eot);
    src: local('HeliosCondC Bold Italic'), local('HeliosCondC-BoldItalic'),
        url(/static/media/HeliosCondC-BoldItalic.1e63cc9b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosCondC-BoldItalic.097e1ec0.woff2) format('woff2'),
        url(/static/media/HeliosCondC-BoldItalic.3cb19b75.woff) format('woff'),
        url(/static/media/HeliosCondC-BoldItalic.c98fcd8a.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosC';
    src: url(/static/media/HeliosC-Italic.85e87f7f.eot);
    src: local('HeliosC Italic'), local('HeliosC-Italic'),
        url(/static/media/HeliosC-Italic.85e87f7f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosC-Italic.2a9ac275.woff2) format('woff2'),
        url(/static/media/HeliosC-Italic.98fd973c.woff) format('woff'),
        url(/static/media/HeliosC-Italic.ffb8e7f7.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosLightC';
    src: url(/static/media/HeliosLightC.6ea1381a.eot);
    src: local('HeliosLightC'),
        url(/static/media/HeliosLightC.6ea1381a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosLightC.09503f63.woff2) format('woff2'),
        url(/static/media/HeliosLightC.b332c665.woff) format('woff'),
        url(/static/media/HeliosLightC.b2e92020.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtLightC';
    src: url(/static/media/HeliosExtLightC-Italic.82adeefc.eot);
    src: local('HeliosExtLightC Italic'), local('HeliosExtLightC-Italic'),
        url(/static/media/HeliosExtLightC-Italic.82adeefc.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosExtLightC-Italic.2a947f7b.woff2) format('woff2'),
        url(/static/media/HeliosExtLightC-Italic.2877dfa0.woff) format('woff'),
        url(/static/media/HeliosExtLightC-Italic.21d87696.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosCondC';
    src: url(/static/media/HeliosCondC-Italic.78a432f4.eot);
    src: local('HeliosCondC Italic'), local('HeliosCondC-Italic'),
        url(/static/media/HeliosCondC-Italic.78a432f4.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosCondC-Italic.1503be14.woff2) format('woff2'),
        url(/static/media/HeliosCondC-Italic.424c5a29.woff) format('woff'),
        url(/static/media/HeliosCondC-Italic.91cd16e5.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosC';
    src: url(/static/media/HeliosC.f129f682.eot);
    src: local('HeliosC'),
        url(/static/media/HeliosC.f129f682.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosC.fb15859e.woff2) format('woff2'),
        url(/static/media/HeliosC.2de178a1.woff) format('woff'),
        url(/static/media/HeliosC.8611a9eb.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosCondC';
    src: url(/static/media/HeliosCondC-Bold.cd0046d5.eot);
    src: local('HeliosCondC Bold'), local('HeliosCondC-Bold'),
        url(/static/media/HeliosCondC-Bold.cd0046d5.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosCondC-Bold.59a1589b.woff2) format('woff2'),
        url(/static/media/HeliosCondC-Bold.ec7f78bd.woff) format('woff'),
        url(/static/media/HeliosCondC-Bold.79e20ba8.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtBlackC';
    src: url(/static/media/HeliosExtBlackC-Italic.aeee3351.eot);
    src: local('HeliosExtBlackC Italic'), local('HeliosExtBlackC-Italic'),
        url(/static/media/HeliosExtBlackC-Italic.aeee3351.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosExtBlackC-Italic.eea87c34.woff2) format('woff2'),
        url(/static/media/HeliosExtBlackC-Italic.9f484ab0.woff) format('woff'),
        url(/static/media/HeliosExtBlackC-Italic.6e99fa3e.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosLightC';
    src: url(/static/media/HeliosLightC-Italic.3aca8982.eot);
    src: local('HeliosLightC Italic'), local('HeliosLightC-Italic'),
        url(/static/media/HeliosLightC-Italic.3aca8982.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosLightC-Italic.143d11a3.woff2) format('woff2'),
        url(/static/media/HeliosLightC-Italic.1faf942d.woff) format('woff'),
        url(/static/media/HeliosLightC-Italic.56b39c5c.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosExtC';
    src: url(/static/media/HeliosExtC-Italic.5e51245d.eot);
    src: local('HeliosExtC Italic'), local('HeliosExtC-Italic'),
        url(/static/media/HeliosExtC-Italic.5e51245d.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosExtC-Italic.9114a703.woff2) format('woff2'),
        url(/static/media/HeliosExtC-Italic.69af2cc8.woff) format('woff'),
        url(/static/media/HeliosExtC-Italic.219f569e.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosUltraCompressedC';
    src: url(/static/media/HeliosUltraCompressedC.73e1b644.eot);
    src: local('HeliosUltraCompressedC'),
        url(/static/media/HeliosUltraCompressedC.73e1b644.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosUltraCompressedC.b8440261.woff2) format('woff2'),
        url(/static/media/HeliosUltraCompressedC.67224735.woff) format('woff'),
        url(/static/media/HeliosUltraCompressedC.8a9c77fa.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtBlackC';
    src: url(/static/media/HeliosExtBlackC.5e19c7d0.eot);
    src: local('HeliosExtBlackC'),
        url(/static/media/HeliosExtBlackC.5e19c7d0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosExtBlackC.3b660dbe.woff2) format('woff2'),
        url(/static/media/HeliosExtBlackC.53d20662.woff) format('woff'),
        url(/static/media/HeliosExtBlackC.64d1e8ce.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosCondThinC';
    src: url(/static/media/HeliosCondThinC.209cf050.eot);
    src: local('HeliosCondThinC'),
        url(/static/media/HeliosCondThinC.209cf050.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosCondThinC.287a5986.woff2) format('woff2'),
        url(/static/media/HeliosCondThinC.ce03d851.woff) format('woff'),
        url(/static/media/HeliosCondThinC.29677c52.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosThinC';
    src: url(/static/media/HeliosThinC.9a9034bb.eot);
    src: local('HeliosThinC'),
        url(/static/media/HeliosThinC.9a9034bb.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosThinC.bc2756c7.woff2) format('woff2'),
        url(/static/media/HeliosThinC.fef2cdd5.woff) format('woff'),
        url(/static/media/HeliosThinC.38922edd.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtC';
    src: url(/static/media/HeliosExtC-Bold.5ebca551.eot);
    src: local('HeliosExtC Bold'), local('HeliosExtC-Bold'),
        url(/static/media/HeliosExtC-Bold.5ebca551.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosExtC-Bold.2fc48492.woff2) format('woff2'),
        url(/static/media/HeliosExtC-Bold.6105194a.woff) format('woff'),
        url(/static/media/HeliosExtC-Bold.8a60329e.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtraCompressedC';
    src: url(/static/media/HeliosExtraCompressedC.5a7ce3db.eot);
    src: local('HeliosExtraCompressedC'),
        url(/static/media/HeliosExtraCompressedC.5a7ce3db.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosExtraCompressedC.89bb9b74.woff2) format('woff2'),
        url(/static/media/HeliosExtraCompressedC.62e030f0.woff) format('woff'),
        url(/static/media/HeliosExtraCompressedC.ae078ece.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosBlackC';
    src: url(/static/media/HeliosBlackC-Italic.11e230a2.eot);
    src: local('HeliosBlackC Italic'), local('HeliosBlackC-Italic'),
        url(/static/media/HeliosBlackC-Italic.11e230a2.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosBlackC-Italic.186f3fea.woff2) format('woff2'),
        url(/static/media/HeliosBlackC-Italic.d6b2852f.woff) format('woff'),
        url(/static/media/HeliosBlackC-Italic.ff9ca5e6.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosC';
    src: url(/static/media/HeliosC-BoldItalic.eb7b754f.eot);
    src: local('HeliosC Bold Italic'), local('HeliosC-BoldItalic'),
        url(/static/media/HeliosC-BoldItalic.eb7b754f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosC-BoldItalic.70ae2ddc.woff2) format('woff2'),
        url(/static/media/HeliosC-BoldItalic.45503daf.woff) format('woff'),
        url(/static/media/HeliosC-BoldItalic.77139c76.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosCondLightC';
    src: url(/static/media/HeliosCondLightC.f6c57919.eot);
    src: local('HeliosCondLightC'),
        url(/static/media/HeliosCondLightC.f6c57919.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosCondLightC.4d145264.woff2) format('woff2'),
        url(/static/media/HeliosCondLightC.3552cc54.woff) format('woff'),
        url(/static/media/HeliosCondLightC.a38a133c.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosCondC';
    src: url(/static/media/HeliosCondC.a84dbc8c.eot);
    src: local('HeliosCondC'),
        url(/static/media/HeliosCondC.a84dbc8c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosCondC.27a61958.woff2) format('woff2'),
        url(/static/media/HeliosCondC.22510a6d.woff) format('woff'),
        url(/static/media/HeliosCondC.72e2a250.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosCondLightC';
    src: url(/static/media/HeliosCondLightC-Italic.a0c84063.eot);
    src: local('HeliosCondLightC Italic'), local('HeliosCondLightC-Italic'),
        url(/static/media/HeliosCondLightC-Italic.a0c84063.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosCondLightC-Italic.261f0ffe.woff2) format('woff2'),
        url(/static/media/HeliosCondLightC-Italic.2d8846b6.woff) format('woff'),
        url(/static/media/HeliosCondLightC-Italic.5f185260.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosCondBlackC';
    src: url(/static/media/HeliosCondBlackC.6b195b6b.eot);
    src: local('HeliosCondBlackC'),
        url(/static/media/HeliosCondBlackC.6b195b6b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosCondBlackC.6bfa96e4.woff2) format('woff2'),
        url(/static/media/HeliosCondBlackC.0d4baee7.woff) format('woff'),
        url(/static/media/HeliosCondBlackC.77795ac8.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosThinC';
    src: url(/static/media/HeliosThinC-Italic.6bd1fa9c.eot);
    src: local('HeliosThinC Italic'), local('HeliosThinC-Italic'),
        url(/static/media/HeliosThinC-Italic.6bd1fa9c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosThinC-Italic.012641dc.woff2) format('woff2'),
        url(/static/media/HeliosThinC-Italic.cf85faec.woff) format('woff'),
        url(/static/media/HeliosThinC-Italic.4a5f654a.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosC';
    src: url(/static/media/HeliosC-Bold.76de5b76.eot);
    src: local('HeliosC Bold'), local('HeliosC-Bold'),
        url(/static/media/HeliosC-Bold.76de5b76.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosC-Bold.01649d31.woff2) format('woff2'),
        url(/static/media/HeliosC-Bold.d7923176.woff) format('woff'),
        url(/static/media/HeliosC-Bold.851cdf6f.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtC';
    src: url(/static/media/HeliosExtC-BoldItalic.9508cdf5.eot);
    src: local('HeliosExtC Bold Italic'), local('HeliosExtC-BoldItalic'),
        url(/static/media/HeliosExtC-BoldItalic.9508cdf5.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosExtC-BoldItalic.007a5ed3.woff2) format('woff2'),
        url(/static/media/HeliosExtC-BoldItalic.c46d80eb.woff) format('woff'),
        url(/static/media/HeliosExtC-BoldItalic.afa3027f.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'HeliosBlackC';
    src: url(/static/media/HeliosBlackC.51e4ff30.eot);
    src: local('HeliosBlackC'),
        url(/static/media/HeliosBlackC.51e4ff30.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosBlackC.ee457aa0.woff2) format('woff2'),
        url(/static/media/HeliosBlackC.637e9486.woff) format('woff'),
        url(/static/media/HeliosBlackC.bd8918c6.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtThinC';
    src: url(/static/media/HeliosExtThinC.b73758c1.eot);
    src: local('HeliosExtThinC'),
        url(/static/media/HeliosExtThinC.b73758c1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosExtThinC.87f32734.woff2) format('woff2'),
        url(/static/media/HeliosExtThinC.879b9c60.woff) format('woff'),
        url(/static/media/HeliosExtThinC.b1e0be66.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosExtLightC';
    src: url(/static/media/HeliosExtLightC.205e1862.eot);
    src: local('HeliosExtLightC'),
        url(/static/media/HeliosExtLightC.205e1862.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosExtLightC.6cdcc4a0.woff2) format('woff2'),
        url(/static/media/HeliosExtLightC.dd6d1a73.woff) format('woff'),
        url(/static/media/HeliosExtLightC.aaef88e1.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosCompressedC';
    src: url(/static/media/HeliosCompressedC.8d9cffa0.eot);
    src: local('HeliosCompressedC'),
        url(/static/media/HeliosCompressedC.8d9cffa0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/HeliosCompressedC.7fd041e1.woff2) format('woff2'),
        url(/static/media/HeliosCompressedC.f3ad5823.woff) format('woff'),
        url(/static/media/HeliosCompressedC.93cd2f33.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}



body {
  margin: 0;
  font-family: Montserrat,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-width: 500px;
}

* {
  transition: all .1s ease-in;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Document
 * ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
 * ========================================================================== */

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * Show the overflow in Edge 18- and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */

textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */

